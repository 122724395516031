import React from "react";
import { Datatable } from "../../components/datatable";
import { StateLabel } from "../../components/stateLabel";

export const Settings = () => {
  // =========== Datatable Configuration ============
  const data = [
    {
      name: "Bebidas calientes",
      img: "",
      description: "Bebidas calientes preparadas en cocina",
      quantity: 20,
    },
    {
      name: "Cafés",
      img: "",
      description: "Cafés preparados en cocina",
      quantity: 15,
    },
  ];
  const sections = [{ text: null, callback: () => null, actions: ["search", "select"], actionsWithSelect: ["clone", "delete", "cancel"] }];
  const customHeaders = {
    name: "Nombre",
    img: "Imagen",
    description: "Descripción",
    quantity: "Nº de productos",
  };
  const customData = {
    img: (data) => <div className="datatable-img">-</div>,
  };
  // ================================================

  return (
    <>
      <div className="datatableContainer">{<Datatable data={data} customHeaders={customHeaders} customData={customData} sections={sections} />}</div>
    </>
  );
};
