import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";
//Components
import { MdLightMode, MdModeNight } from "react-icons/md";
import { IoMdCog } from "react-icons/io";
import { IoHome, IoLogOut, IoCloseOutline, IoMenuOutline } from "react-icons/io5";
import Logo from "./../../assets/logo/logo.jpg";
import "./style.css";

export const Menu = () => {
  const [menuActive, setMenuActive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
    setMenuActive(!menuActive);
  };

  const handleMenu = (component) => {
    dispatch(setComponentActive(component));
    if (component === "dashboard") navigate("/");
    else navigate("/" + component);
    setMenuActive(!menuActive);
  };

  const FloatMenu = () => {
    return (
      <>
        <div className="menu-close" onClick={() => setMenuActive(!menuActive)}>
          <IoCloseOutline />
        </div>
        <div className="floatMenu">
          <div className="menu-top">
            <div className="menu-logo">
              <img alt="logo-bussiness" src={Logo} />
            </div>

            <div className="menu-list">
              <div className={componentActive === "dashboard" ? "menu-item selected" : "menu-item"} onClick={() => handleMenu("dashboard")}>
                <IoHome /> Gestor de pedidos
              </div>
              <div className={componentActive === "listdisplay" ? "menu-item selected" : "menu-item"} onClick={() => handleMenu("listdisplay")}>
                <IoHome /> Historial de pedidos
              </div>
              <div className={componentActive === "settings" ? "menu-item selected" : "menu-item"} onClick={() => handleMenu("settings")}>
                <IoHome /> Configuración
              </div>
              <div className="menu-item" onClick={() => logOff}>
                <IoLogOut /> Salir
              </div>
            </div>
          </div>

          <div className="menu-bottom">
            <div className="kitchen-mode">
              <MdLightMode className="selected" />
              <MdModeNight />
            </div>

            <div className="kitchen-guide">
              <div className="kitchen-guide-title">Estados del producto</div>
              <div className="kitchen-guide-state">
                <div className="circle-state circle-white"></div>
                <div>Pendiente por preparar</div>
              </div>
              <div className="kitchen-guide-state">
                <div className="circle-state circle-preparation"></div>
                <div>En preparación</div>
              </div>
              <div className="kitchen-guide-state">
                <div className="circle-state circle-prepared"></div>
                <div>Preparado</div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-shadow"></div>
      </>
    );
  };

  const ActiveButtonMenu = () => {
    return (
      <div className="activeButtonMenu" onClick={() => setMenuActive(!menuActive)}>
        <div>
          <IoMenuOutline />
        </div>
        <div>Menu</div>
      </div>
    );
  };

  return !menuActive ? <ActiveButtonMenu /> : <FloatMenu />;
};
