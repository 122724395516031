import React from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../reducers/userConfigSlice";
import { generateRandomOrder } from "./../mockData";
//Components
import { FloatComponent } from "../listDisplay/floatComponent";
import { FloatPanel } from "../../components/floatPanel";
import { Datatable } from "../../components/datatable";
import { StateLabel } from "../../components/stateLabel";
import "./style.css";

export const ListDisplay = () => {
  const dispatch = useDispatch();
  const generateOrders = Array.from({ length: 15 }, (_, index) => generateRandomOrder(index + 1));
  const generateOrdersTable = eliminarClavesDeObjetos(
    Array.from({ length: 15 }, (_, index) => generateRandomOrder(index + 1)),
    ["id", "order"]
  );

  function eliminarClavesDeObjetos(arr, clavesAEliminar) {
    return arr.map((obj) => {
      let nuevoObj = { ...obj };
      clavesAEliminar.forEach((clave) => {
        delete nuevoObj[clave];
      });
      return nuevoObj;
    });
  }

  // =========== Datatable Configuration ============
  const sections = [{ text: null, callback: () => null, actions: ["search", "select"], actionsWithSelect: ["clone", "delete", "cancel"] }];
  const customHeaders = {
    code: "Nº de pedido",
    deliveryType: "Entrega/Envio",
    state: "Estado del pedido",
    date: "Hora/Fecha",
  };
  const customData = {
    state: (data) => (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StateLabel state={data} />
      </div>
    ),
  };
  // ================================================

  const handleFloatPanelVisibility = (data = null) => {
    console.log("handleFloatPanelVisibility");
    console.log(data);
    console.log(generateOrders);
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={generateOrders.filter((order) => order.code === data.code)[0]} />));
  };

  return (
    <>
      <div className="datatableContainer">
        {
          <Datatable
            data={generateOrdersTable}
            customHeaders={customHeaders}
            customData={customData}
            sections={sections}
            rowCallback={handleFloatPanelVisibility}
          />
        }
        <FloatPanel />
      </div>
    </>
  );
};
