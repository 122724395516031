import { useState } from "react";
import { CiClock2 } from "react-icons/ci";

export const Order = ({ data, partial = null, start = false }) => {
  const [orderData, updateOrderData] = useState(data);

  const handleStateOrderData = (ingredientId) => {
    const orderDataCopy = { ...orderData };
    const ingredient = orderDataCopy.order.filter((order) => order.id === ingredientId);
    if (ingredient.length > 0) {
      //Process
      if (ingredient[0].state === 0) ingredient[0].state = 1;
      //Finish
      else if (ingredient[0].state === 1) ingredient[0].state = 2;
      //Restart
      else if (ingredient[0].state === 2) ingredient[0].state = 0;
    }
    updateOrderData(orderDataCopy);
  };

  const handleAllStateOrderData = (newState) => {
    console.log("handleAllStateOrderData");
    updateOrderData((prevData) => ({
      ...prevData,
      order: prevData.order.map((item) => ({
        ...item,
        state: newState,
      })),
      state: newState,
    }));
    console.log({ ...orderData, state: newState });
  };

  const Header = ({ orderData }) => {
    const orderFinished = orderData.order.length === orderData.order.filter((f) => f.state === 2).length ? true : false;
    const actionText = orderData.state === 0 ? "Iniciar todo" : orderData.state === 1 ? "Terminar todo" : "Reiniciar todo";
    return (
      <div className="orderHeader">
        <div className="orderHeaderRow">
          <div className="orderHeaderTitle">{orderData.code}</div>
          <div className="orderHeader-timer">
            <CiClock2 /> 02:25
          </div>
        </div>
        <div className="orderHeaderRow">
          <div>
            <small>16:03 - 15/07</small>
          </div>
          <div className="orderHeader-handleState">
            <button
              className={orderFinished ? "orderActionConfirm" : ""}
              onClick={() => !orderFinished && handleAllStateOrderData(orderData.state === 0 ? 1 : orderData.state === 1 ? 2 : 0)}
            >
              {!orderFinished ? actionText : "Confirmar"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const BodyList = ({ ingredient, ingredientIndex }) => {
    const actionText = ingredient.state === 0 ? "Iniciar" : ingredient.state === 1 ? "Terminar" : "Reiniciar";
    return (
      <div className={"orderItem orderItemState-" + ingredient.state} key={ingredientIndex}>
        <div className="orderItemHeader">
          <div className="orderItemQuantity">{ingredient.quantity}</div>
          <div className="orderItemName">{ingredient.name}</div>
          <div className="orderItem-handleState">
            <button onClick={() => handleStateOrderData(ingredient.id)}>{actionText}</button>
          </div>
        </div>
        <div className="orderItemExtra">
          {ingredient.extra.map((i, index) => (
            <div key={i}>
              {i} {ingredient.extra.length > index + 1 ? "-" : ""}{" "}
            </div>
          ))}
        </div>
        <div className="orderItemNotes">{ingredient.notes}</div>
      </div>
    );
  };
  const NormalOrder = () => {
    return (
      <>
        <Header orderData={orderData} />
        <div className="orderBody">
          {orderData.order.map((ingredient, ingredientIndex) => (
            <BodyList ingredient={ingredient} ingredientIndex={ingredientIndex} key={ingredientIndex} />
          ))}
        </div>
      </>
    );
  };
  const PartialOrder = () => {
    return start ? (
      <>
        <Header code={orderData.code} />
        <div className="orderBody">
          {orderData.order.map(
            (ingredient, ingredientIndex) =>
              ingredientIndex * 25 < parseInt(partial) && <BodyList ingredient={ingredient} ingredientIndex={ingredientIndex} key={ingredientIndex} />
          )}
        </div>
      </>
    ) : (
      <>
        <div className="orderBody">
          {orderData.order.map(
            (ingredient, ingredientIndex) =>
              ingredientIndex * 25 > parseInt(partial) && <BodyList ingredient={ingredient} ingredientIndex={ingredientIndex} key={ingredientIndex} />
          )}
        </div>
      </>
    );
  };
  return partial === null ? <NormalOrder /> : <PartialOrder />;
};
