import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../components/customBlock";
import { Order } from "../kitchenDisplay/order";
import { StateLabel } from "../../components/stateLabel";
import { Select } from "../../components/select";
import { Input } from "../../components/inputs/type2";

export const FloatComponent = ({ data }) => {
  const Details = () => {
    return (
      <div className="categoryDetails">
        <Order data={data} />
      </div>
    );
  };

  const InvoiceResume = () => {
    return (
      <div className="pt-3">
        <div className="invoiceResume-Header">
          <div>
            <div className="invoiceResume-Header-title">No Factura</div>
            <div className="invoiceResume-Header-value">31542-F1</div>
          </div>
          <div>
            <div className="invoiceResume-Header-title text-align-right">Canal de venta</div>
            <div className="invoiceResume-Header-value text-align-right">Tienda online</div>
          </div>
        </div>
        <div className="invoiceResume-Body">
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Subtotal</div>
            <div className="invoiceResume-note">2 artículos</div>
            <div className="invoiceResume-value">49,98€</div>
          </div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Descuento</div>
            <div className="invoiceResume-note">X9FJBZ8P</div>
            <div className="invoiceResume-value">-10,99€</div>
          </div>
          <div className="invoiceResume-Separator"></div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Total</div>
            <div></div>
            <div className="invoiceResume-value">38,99€</div>
          </div>
        </div>
      </div>
    );
  };

  const ClientDetails = () => {
    return (
      <div className="row gap-2 pt-2">
        <div className="col-12">
          <Input
            disabled={true}
            id={"nameInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Nombre/Razón Social"
            description="Escriba el nombre del cliente o la razón social"
          />
        </div>
        <div className="col-12">
          <Input
            disabled={true}
            id={"nifInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="NIF"
            description="Escriba el número de identificador fiscal"
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <Input
                disabled={true}
                id={"emailInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Email"
                description="Escriba el email"
              />
            </div>
            <div className="col-6">
              <Input
                disabled={true}
                id={"phoneInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Teléfono"
                description="Escriba el número de teléfono"
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de envío</div>

        <div className="col-12">
          <Input
            disabled={true}
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                disabled={true}
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de envío secundaria</div>

        <div className="col-12">
          <Input
            disabled={true}
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                disabled={true}
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de facturación</div>

        <div className="col-12">
          <Input disabled={true} type="checkbox" /> Igual que la dirección de envío
        </div>

        <div className="col-12">
          <Input
            disabled={true}
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                disabled={true}
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                disabled={true}
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const content = {
    title: "Detalles del pedido",
    leftActions: [
      { content: <StateLabel state={data.state} /> },
      {
        content: (
          <div style={{ width: "150px" }}>
            <Select values={[{ id: 0, name: "En preparación" }]} callback={() => null} label="" description="" classname={""} />
          </div>
        ),
      },
    ],
    rightActions: [],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Detalles"} content={<Details />} />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <CustomBlock name={""} content={<InvoiceResume />} description={""} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <CustomBlock name={""} content={<ClientDetails />} description={""} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
