import React, { useState, useEffect, useRef } from "react";

//Components
import ReactDOM from "react-dom";
import { generateRandomOrder } from "./../mockData";
import { Order } from "./order";

import "./style.css";

const orders = Array.from({ length: 15 }, (_, index) => generateRandomOrder(index + 1));

export const KitchenDisplay = () => {
  return (
    <>
      <div className="kitchen-display">
        {orders.map((order) => (
          <div className="order">
            <Order data={order} />
          </div>
        ))}
      </div>
    </>
  );
};
