import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import { KitchenDisplay } from "./pages/kitchenDisplay";
import { ProtectedRoute } from "./components/security/protectedRoute";
import { Settings } from "./pages/settings";
import { ListDisplay } from "./pages/listDisplay";
import { Menu } from "./pages/menu";
//Functions
//import useLanguage from "./functions/useLanguage";

function App() {
  //useLanguage();

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Menu />
                <KitchenDisplay />
              </>
            }
          />
          <Route
            path="/settings"
            element={
              <>
                <Menu />
                <Settings />
              </>
            }
          />
          <Route
            path="/listdisplay"
            element={
              <>
                <Menu />
                <ListDisplay />
              </>
            }
          />
          {/*<Route
            path="/admin"
            element={
              <ProtectedRoute page="admin">
                <Admin />
              </ProtectedRoute>
            }
          />*/}
        </Routes>
      </Router>
    </>
  );
}

export default App;
