const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const ingredients = [
  "Cebolla",
  "Picante",
  "Limón",
  "Ajo",
  "Guacamole",
  "Sour Cream",
  "Queso",
  "Cilantro",
  "Jalapeños",
  "Piña",
  "Crutones",
  "Tofu",
  "Parmesano",
  "Perejil",
];
const foodNames = [
  "Hamburguesa",
  "Pizza Margherita",
  "Taco al Pastor",
  "Sushi Roll",
  "Pasta Alfredo",
  "Pollo Asado",
  "Burrito de Carne",
  "Enchiladas Verdes",
  "Ceviche",
  "Ramen",
  "Paella",
  "Falafel",
  "Curry de Pollo",
  "Bagel de Salmón",
  "Tarta de Manzana",
];

const generateRandomExtras = () => {
  const extraCount = getRandomInt(0, 4);
  const shuffled = ingredients.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, extraCount);
};

const generateRandomNotes = (extras) => {
  if (extras.length === 0) return "";
  const noteOptions = [`extra de ${extras[0]}`, `sin ${extras[0]}`, ""];
  return noteOptions[getRandomInt(0, noteOptions.length - 1)];
};

const deliveryTypes = ["Domicilio", "Local"];

export const generateRandomOrder = (id) => ({
  id,
  code: `31${String(id).padStart(3, "0")}-${getRandomInt(1, 2) === 2 ? "GLV" : "LO"}`,
  date: "2024-08-05",
  order: Array.from({ length: getRandomInt(1, 6) }, (_, index) => {
    const extras = generateRandomExtras();
    return {
      name: foodNames[getRandomInt(0, foodNames.length - 1)],
      quantity: getRandomInt(1, 3),
      state: 0,
      extra: extras,
      notes: generateRandomNotes(extras),
      id: getRandomInt(100000, 999999),
    };
  }),
  deliveryType: deliveryTypes[getRandomInt(0, 1)],
  state: 0,
});
