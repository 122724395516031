//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateDatatableConfiguration } from "../../reducers/userConfigSlice";
import { Select } from "./../select";
import "./style.css";

export const Pagination = () => {
  const dispatch = useDispatch();
  const configuration = useSelector((state) => state.aplicationConfig.userConfig.datatable.configuration);
  const maxRows = useSelector((state) => state.aplicationConfig.userConfig.datatable.configuration.maxRows);

  const handleMaxRows = (value) => {
    dispatch(updateDatatableConfiguration({ ...configuration, maxRows: value }));
  };

  const handlePage = (page) => {
    dispatch(updateDatatableConfiguration({ ...configuration, indexStart: page * maxRows }));
  };

  return (
    <div className="pagination">
      <div className="paginationDropdown">
        <div className="paginationText">Mostrar</div>
        <div>
          <Select
            values={[
              { id: 0, name: "12" },
              { id: 1, name: "24" },
            ]}
            callback={handleMaxRows}
          />
        </div>
        <div className="paginationText">por página</div>
      </div>
      <div className="paginationPages">
        {configuration.pages.map((p) => {
          return (
            <div className={(configuration.indexStart + 12) / maxRows === p + 1 ? "pageBtn pageBtnActive" : "pageBtn"} onClick={() => handlePage(p)}>
              {p + 1}
            </div>
          );
        })}
      </div>
    </div>
  );
};
